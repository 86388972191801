.image-container {
  display: inline-block;
  position: relative;
}
.bg {
  transform: rotate(-25deg) rotateY(-6deg) rotateX(60deg) skewY(41deg);
  top: 25%;
  height: 100%;
  /* right: 8%; */
  width: 100%;
  left: 0%;
  margin-left: -25px;
  position: absolute;
}

.star-button {
  position: absolute;
  /* height: 80vh; */
  width: 100%;
  left: 0%;
  top: 0%;
}

.secondpicbg {
  transform: rotate(-25deg) rotateY(-6deg) rotateX(60deg) skewY(41deg);
  height: 320px;
  right: -44vh;
  width: 320px;
  position: absolute;
}
.spic2 {
  position: absolute;
  height: 250px;
  width: 362px;
  left: 0%;
  top: 0%;
}

.divcolors {
  border-radius: 10px;
}

.pagination li {
  color: black;
  /* height: 32px;
  width: 32px; */
  /* float: left; */
  padding: 8px 16px;
  margin-right: 10px;
  border-radius: 4px;
  background-color: white;
  /* text-decoration: none; */
}

.pagination li.active {
  background-color: #051c48;
  color: white;
}

.pagination li:hover:not(.active) {
  background-color: #ddd;
}

.mantine-bbtlhy[data-active] {
  background-color: #051c48;
}

.overlay {
  position: absolute;
  top: 4%;
  left: 4%;
  right: 4%;
  bottom: 30%;
  opacity: 0;
  transition: opacity 0.4s ease-in-out;
  background: rgb(80, 98, 131);
  cursor: pointer;
}

.content {
  position: absolute;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  text-align: center;
  opacity: 0;
  transition: opacity 0.4s ease-in-out;
}

.container:hover .overlay {
  opacity: 0.8;
}

.container:hover .content {
  opacity: 1;
}

.mantine-UnstyledButton-root {
  background-color: white;
  padding: 7px;
}

.mantine-Carousel-control svg {
  color: #e27612;
  width: 25px;
  height: 25px;
}

.custom-radios div {
  display: inline-block;
}
.custom-radios input[type="radio"] {
  display: none;
}

.custom-radios input[type="radio"] + label span {
  display: inline-block;
  width: 30px;
  height: 30px;
  margin-left: 2px;
  margin-top: 10px;
  margin-bottom: 3px;

  cursor: pointer;
  border-radius: 50%;
}
.custom-radios input[type="radio"] + label span img {
  opacity: 0;
  transition: all 0.3s ease-in-out;
  margin-top: -9px;
}
.custom-radios input[type="radio"]#color-1 + label span {
  background-color: #ffffff;
}
.custom-radios input[type="radio"]#color-2 + label span {
  background-color: #ffffff;
}
.custom-radios input[type="radio"]#color-3 + label span {
  background-color: #ffffff;
}
.custom-radios input[type="radio"]:checked#color-1 + label span {
  background-color: #e27612;
}
.custom-radios input[type="radio"]:checked#color-2 + label span {
  background-color: #e27612;
}
.custom-radios input[type="radio"]:checked#color-3 + label span {
  background-color: #e27612;
}

.custom-radios input[type="radio"]:checked + label span img {
  opacity: 1;
  padding-top: 8px;
  margin-top: -9px;
}
/* 
.situation-box1 {
  width: 450px;
  margin: 0px auto;
  position: relative;
  height: 600px;
}

.situation1 {
  width: 450px;
  margin: 0px auto;
  position: relative;
  height: 600px;
  overflow: hidden;
  perspective: 100px;
  z-index: 10;
}
.situation-pattern1 {
  height: 2400px;
  width: 800px;
}
.situation-pattern-container1 {
  transform: scaleX(1.8) scaleY(1) scaleZ(0.5) rotateX(45deg) rotateY(0deg)
    rotateZ(0deg) translateX(-100px) translateY(-1400px) translateZ(0px)
    skewX(0deg) skewY(0deg);
  z-index: 10;
  position: absolute;
}

.situation2 {
  width: 750px;
  margin: 0px auto;
  position: relative;
  height: 500px;
  overflow: hidden;
  perspective: 4px;
  z-index: 10;
}

.situation-box2 {
  width: 750px;
  margin: 0px auto;
  position: relative;
  height: 500px;
}
.situation-pattern2 {
  height: 450px;
  width: 650px;
}

.situation-pattern-container2 {
  transform: scaleX(0.5) scaleY(0.2) scaleZ(0.02) rotateX(10deg) rotateY(0deg)
    rotateZ(0deg) translateX(50px) translateY(500px) translateZ(0px) skewX(0deg)
    skewY(0deg);
  z-index: 10;
  position: absolute;
}
.situation-box2 {
  width: 750px;
  margin: 0px auto;
  position: relative;
  height: 500px;
}
.situation3 {
  width: 750px;
  margin: 0px auto;
  position: relative;
  height: 500px;
  overflow: hidden;
}

.situation-pattern3 {
  height: 540px;
  width: 280px;
  transform: scale(2.6) rotateZ(90deg) translateY(-100px);
}
.situation-pattern-container3 {
  z-index: 10;
  position: relative;
}

.situation-image {
  position: absolute;
  z-index: 999;
  top: 0px;
  left: 0px;
  transform: scaleX(1) scaleY(1) scaleZ(1) rotateX(0deg) rotateY(0deg)
    rotateZ(0deg) translateX(0px) translateY(0px) translateZ(100px) skewX(0deg)
    skewY(0deg);
}

.situation-image-box {
  position: absolute;
  z-index: 999;
  top: 0px;
  left: 0px;
} */

/* Default for Laptop 1440px and above */
.situation-box1,
.situation1 {
  width: 450px;
  height: 600px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  perspective: 100px;
  z-index: 10;
}

.situation-pattern1 {
  height: 2400px;
  width: 800px;
}

/* Laptop 1024px */
@media (max-width: 1440px) and (min-width: 1024px) {
  .situation-box1,
  .situation1 {
    width: 450px;
    height: 600px;
  }
}

/* Tablet 768px */
@media (max-width: 1024px) and (min-width: 768px) {
  .situation-box1,
  .situation1 {
    width: 387px;
    height: 516px;
  }
}

/* Mobile L 425px */
@media (max-width: 768px) and (min-width: 425px) {
  .situation-box1,
  .situation1 {
    width: 425px;
    height: 566px;
  }
}

/* Mobile M 375px */
@media (max-width: 425px) and (min-width: 375px) {
  .situation-box1,
  .situation1 {
    width: 375px;
    height: 500px;
  }
}

/* Mobile S 320px */
@media (max-width: 375px) and (min-width: 320px) {
  .situation-box1,
  .situation1 {
    width: 320px;
    height: 426px;
  }
}

.situation-pattern-container1 {
  transform: scaleX(1.8) scaleY(1) scaleZ(0.5) rotateX(45deg) rotateY(0deg)
    rotateZ(0deg) translateX(-100px) translateY(-1400px) translateZ(0px)
    skewX(0deg) skewY(0deg);
  z-index: 10;
  position: absolute;
}

.situation-pattern2 {
  height: 666px;
  width: 900px;
}

.situation2,
.situation-box2 {
  width: 750px;
  height: 500px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  z-index: 10;
}

.situation2,
.situation-box2 {
  perspective: 4px;
}

.situation-pattern-container2 {
  /* transform: scaleX(0.6) scaleY(0.2) scaleZ(0.02) rotateX(10deg)
    translateX(10px) translateY(400px); */
  transform: scaleX(0.6) scaleY(0.2) scaleZ(0.02) rotateX(10deg)
    translateX(-100px) translateY(250px);
  z-index: 10;
  position: absolute;
}

/* Responsive Adjustments */
@media (max-width: 1440px) {
  .situation2,
  .situation-box2 {
    width: 750px;
    height: 500px;
  }
}

@media (max-width: 1024px) {
  .situation2,
  .situation-box2 {
    width: 563px;
    height: 375px;
  }
  .situation-pattern-container2 {
    transform: scaleX(0.6) scaleY(0.2) scaleZ(0.03) rotateX(10deg)
      translateX(-265px) translateY(170px);
  }
}

@media (max-width: 768px) {
  .situation2,
  .situation-box2 {
    width: 387px;
    height: 285px;
  }
  .situation-pattern-container2 {
    transform: scaleX(0.6) scaleY(0.4) scaleZ(0.03) rotateX(10deg)
      translateX(-380px) translateY(-475px);
  }
}

@media (max-width: 425px) {
  .situation2,
  .situation-box2 {
    width: 425px;
    height: 283px;
  }
  .situation-pattern-container2 {
    transform: scaleX(0.6) scaleY(0.4) scaleZ(0.03) rotateX(10deg)
      translateX(-380px) translateY(-415px);
  }
}

@media (max-width: 375px) {
  .situation2,
  .situation-box2 {
    width: 375px;
    height: 250px;
  }
}

@media (max-width: 320px) {
  .situation2,
  .situation-box2 {
    width: 320px;
    height: 213px;
  }
}

.situation-image {
  position: absolute;
  z-index: 999;
  top: 0px;
  left: 0px;
  transform: scaleX(1) scaleY(1) scaleZ(1) rotateX(0deg) rotateY(0deg)
    rotateZ(0deg) translateX(0px) translateY(0px) translateZ(100px) skewX(0deg)
    skewY(0deg);
}

.situation-image-box {
  z-index: 999;
  top: 0px;
  left: 0px;
}

.situation-box11,
.situation11 {
  width: 257px;
  height: 343px;
  /* margin: 0 auto; */
  position: relative;
  overflow: hidden;
  perspective: 100px;
  z-index: 10;
}

.situation-pattern11 {
  height: 2400px;
  width: 800px;
}

.situation-pattern-container11 {
  transform: scaleX(1.8) scaleY(1) scaleZ(0.5) rotateX(45deg) rotateY(0deg)
    rotateZ(0deg) translateX(-100px) translateY(-1400px) translateZ(0px)
    skewX(0deg) skewY(0deg);
  z-index: 10;
  position: absolute;
}

.situation-pattern22 {
  height: 666px;
  width: 900px;
}

.situation22,
.situation-box22 {
  width: 257px;
  height: 171px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  z-index: 10;
}

.situation22,
.situation-box22 {
  perspective: 4px;
}

.situation-pattern-container22 {
  transform: scaleX(0.5) scaleY(0.2) scaleZ(0.02) rotateX(10deg)
    translateX(-215px) translateY(170px);
}

@media (max-width: 2560px) {
  .situation-pattern-container22 {
    transform: scaleX(0.6) scaleY(0.4) scaleZ(0.03) rotateX(10deg)
      translateX(-380px) translateY(-415px);
  }
}

@media (max-width: 1440px) {
  .situation-pattern-container22 {
    transform: scaleX(0.6) scaleY(0.4) scaleZ(0.03) rotateX(10deg)
      translateX(-380px) translateY(-415px);
  }
}
@media (max-width: 1024px) {
  .situation-pattern-container22 {
    transform: scaleX(0.6) scaleY(0.4) scaleZ(0.03) rotateX(10deg)
      translateX(-380px) translateY(-415px);
  }
}

@media (max-width: 768px) {
  .situation-pattern-container22 {
    transform: scaleX(0.6) scaleY(0.4) scaleZ(0.03) rotateX(10deg)
      translateX(-380px) translateY(-475px);
  }
}

@media (max-width: 425px) {
  .situation-pattern-container22 {
    transform: scaleX(0.6) scaleY(0.4) scaleZ(0.03) rotateX(10deg)
      translateX(-380px) translateY(-415px);
  }
}

/* @media (max-width: 2560px) {
  .situation-box11,
  .situation11 {
    width: 390px;
    height: 520px;
  }
  .situation-box22,
  .situation22 {
    width: 390px;
    height: 260px;
  }
} */

/* 1024px */
@media (max-width: 1440px) {
  .situation-box11,
  .situation11 {
    width: 162px;
    height: 216px;
  }
  .situation-box22,
  .situation22 {
    width: 162px;
    height: 108px;
  }
}

/* 768px */
@media (max-width: 1024px) {
  .situation-box11,
  .situation11 {
    width: 125px;
    height: 166px;
  }
  .situation-box22,
  .situation22 {
    width: 125px;
    height: 83px;
  }
}

/* 445px */
@media (max-width: 768px) {
  .situation-box11,
  .situation11 {
    width: 332px;
    height: 443px;
  }
  .situation-box22,
  .situation22 {
    width: 332px;
    height: 221px;
  }
}

/* 375px */
@media (max-width: 445px) {
  .situation-box11,
  .situation11 {
    width: 335px;
    height: 446px;
  }
  .situation-box22,
  .situation22 {
    width: 335px;
    height: 223px;
  }
}

/* 320px */
@media (max-width: 375px) {
  .situation-box11,
  .situation11 {
    width: 285px;
    height: 380px;
  }
  .situation-box22,
  .situation22 {
    width: 285px;
    height: 190px;
  }
}

@media (max-width: 320px) {
  .situation-box11,
  .situation11 {
    width: 230px;
    height: 306px;
  }
  .situation-box22,
  .situation22 {
    width: 230px;
    height: 153px;
  }
}
